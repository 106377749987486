<template>
	<v-dialog v-model="dialog">
		<v-card>
			<v-card-title class="headline grey lighten-2" primary-title>Горячие клавиши</v-card-title>

			<v-card-text>
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th>
									<strong>Горячая клавиша</strong>
								</th>
								<th>
									<strong>Действие</strong>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Пробел, K (Л на русской раскладке)</td>
								<td>Начать или остановить воспроизведение</td>
							</tr>
							<tr>
								<td>N (Т на русской раскладке), Next Track на мультимедийной клавиатуре</td>
								<td>Перейти к следующей серии (если есть)</td>
							</tr>
							<tr>
								<td>P (З на русской раскладке), Previous Track на мультимедийной клавиатуре</td>
								<td>Перейти к предыдущей серии (если есть)</td>
							</tr>
							<tr>
								<td>Стрелки вправо и влево</td>
								<td>Перемотать на 5&nbsp;секунд вперед или назад</td>
							</tr>
							<tr>
								<td>J (О на русской раскладке)</td>
								<td>Перемотать на 10&nbsp;секунд назад</td>
							</tr>
							<tr>
								<td>L (Д на русской раскладке)</td>
								<td>Перемотать на 10&nbsp;секунд вперед</td>
							</tr>
							<tr>
								<td>Стрелки вверх и вниз</td>
								<td>Увеличить или уменьшить громкость на 10%</td>
							</tr>
							<tr>
								<td>Клавиши с цифрами от 0 до 9 (не на дополнительной цифровой клавиатуре)</td>
								<td>Перейти к одной из десяти условных частей видео (0 - начало видео)</td>
							</tr>
							<tr>
								<td>F (А на русской раскладке)</td>
								<td>Перейти в полноэкранный режим или выйти из него</td>
							</tr>
							<tr>
								<td>S (Ы на русской раскладке)</td>
								<td>Показать или скрыть субтитры, если они есть</td>
							</tr>
							<tr>
								<td>A (Ф на русской раскладке), enter</td>
								<td>Следующая звуковая дорожка (если есть)</td>
							</tr>
							<tr>
								<td>shift + &lt; (Б на русской раскладке)</td>
								<td>Уменьшить скорость воспроизведения</td>
							</tr>
							<tr>
								<td>shift + &gt; (Ю на русской раскладке)</td>
								<td>Увеличить скорость воспроизведения</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	data() {
		return {
			dialog: false,
		};
	},
	methods: {
		openDialog() {
			this.dialog = true;
		}
	}
};
</script>
